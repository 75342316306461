import { graphql, Link } from "gatsby"
import * as React from "react"
import Layout from "../components/Layout"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import InformationBox from "../components/InformationBox"
const shortcodes = { InformationBox }

const FaqPageTemplate = ({ data, pageContext }) => {
  const { body, frontmatter } = data.faqs
  const relatedArticles = data.relatedArticles.nodes
  const linksForPages =
    data?.faqs?.fileAbsolutePath?.indexOf("index") != -1
      ? data?.relatedArticles?.nodes.filter((faq: any) => {
          return faq.name !== "index"
        })
      : new Array()
  const {
    breadcrumb: { crumbs },
  } = pageContext
  crumbs?.map((crumb: any) => {
    crumb.crumbLabel = crumb?.crumbLabel?.replaceAll("-", " ")
  })

  console.log("faqs data: ", pageContext)
  return (
    <Layout>
      <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
      <section className="faq-detail">
        {/* <h1 className="page-title">FAQ Details</h1> */}
        <h1 className="page-title">{frontmatter?.title}</h1>
        <div className="d-flex row">
          <div className="content-column">
            <h2 className="page-sub-title blue-color"></h2>
            <p className="faq-date-modified">
              Modified on: {frontmatter?.date}
            </p>
            <p className="seperator"></p>
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{body}</MDXRenderer>
              {linksForPages?.length > 0 && (
                <div className="faq-card landing-page-card">
                  <div className="d-flex row">
                    {linksForPages?.map(
                      (list: any) =>
                        list?.childMdx?.slug && (
                          <div className="column">
                            <div className="content">
                              <div className="d-flex align-items-center ">
                                <img
                                  src={frontmatter?.icon?.publicURL}
                                  alt={frontmatter?.iconAlt}
                                />
                                <Link
                                  to={`/support/faq/${list?.childMdx?.slug}/`}
                                >
                                  {list?.childMdx?.frontmatter?.title}
                                </Link>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              )}
            </MDXProvider>
          </div>

          <div className="related-column">
            {relatedArticles && relatedArticles.length > 0 && (
              <div className="related-articles faq-card">
                <h2 className="page-sub-title">Related Articles</h2>

                <div className="faq-list">
                  {relatedArticles.map((list: any) => (
                    <div className="item">
                      <Link to={`/support/faq/${list?.childMdx?.slug}`}>
                        {list?.childMdx?.frontmatter?.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default FaqPageTemplate

export const query = graphql`
  query faqs($slug: String, $relativeDirectory: String) {
    faqs: mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        icon {
          publicURL
        }
        iconAlt
      }
      slug
      body
      fileAbsolutePath
    }
    relatedArticles: allFile(
      filter: {
        sourceInstanceName: { eq: "faq" }
        relativeDirectory: { eq: $relativeDirectory }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            date
            order
          }
          slug
        }
        relativeDirectory
        name
      }
    }
  }
`
